.Reservation {
  border: 1px solid #ccc;
  border-radius: 15px;
  background-color: #fff;
  opacity: 0.95;
  margin-top: 5%;
  padding: 2%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.indispo {
  color: red;
  font-weight: bold;
}

.title-reservation {
  text-align: center;
  color: rgb(4, 24, 26);
  margin-bottom: 2%;
  font-family: Georgia, Times, serif;
  font-size: 1.5em;
}

.form-css {
  padding: 3%;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.Selectable
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(
    .DayPicker-Day--end
  ):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.Selectable .DayPicker-Day {
  border-radius: 0 !important;
  transition: all 0.3s ease;
}

.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.arrhes {
  font-size: 0.9em;
  color: red;
  font-weight: bold;
}
