.infos {
  .text-infos {
    color: white;
    background: rgba(0, 0, 0, 0.8);
    border: 1px solid black;
    border-radius: 3%;
    padding-top: 3%;
    margin-top: 1%;
  }
  .text-center {
    text-align: center;
  }
  .text-strong {
    font-weight: bold;
  }
  .text-red {
    color: red;
    font-size: 1.2em;
  }
  .text-semaine {
    color: white;
    font-weight: bold;
    font-size: 1.3em;
    text-align: center;
    margin-bottom: 6%;
  }
  .text-plan {
    font-weight: bold;
    margin-bottom: 6%;
    a {
      color: rgb(15, 179, 64);
    }
    text-align: center;
  }

  .arrhes {
    font-size: 0.7em;
  }

  .map-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    margin-bottom: 9%;
  }
  .map-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}
