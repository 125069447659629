.carousel {
  display: flex;
  height: 80vh;
  width: 60vw;
  margin-top: 1%;
  margin-bottom: 10vh;
  justify-content: center;
  gap: 2%;
  transition: all 0.3s ease;
}

.display_carousel {
  display: block;
}

.display_galerie {
  display: none;
}

@media only screen and (max-width: 900px) {
  .display_carousel {
    display: none;
  }
  .display_galerie {
    display: block;
  }
}

.top {
  display: flex;
  margin-top: 10vh;
  margin-bottom: 10vh;
  flex-wrap: wrap;
  justify-content: space-between;
}

.top ul {
  list-style: none;
  width: 100%;
  height: 100%;
  z-index: 1;
  box-sizing: border-box;
  padding: 0;
}

.top ul li {
  position: relative;
  width: 30%;
  height: 30%;
  overflow: hidden;
  margin: 1%;
  border-radius: 12px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.top ul li:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.top ul li::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  content: "";
  color: white;
  opacity: 0.4;
  text-align: center;
  box-sizing: border-box;
  pointer-events: none;
  transition: all 0.5s ease;
}

.top ul li:hover::before {
  opacity: 0;
}

.top ul li img {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.lightbox {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.lightbox img {
  max-width: 90%;
  max-height: 80%;
  position: relative;
  top: 50%;
  transform: translateY(-50%) scale(0.9);
  transition: all 0.5s ease;
}

.lightbox:target {
  outline: none;
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.4s ease;
}

.lightbox:target img {
  transform: translateY(-50%) scale(1);
}
