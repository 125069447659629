.Header{
    text-align: center;
    background:#30323D;
    .text-color{
      color: rgba(255, 255, 255, 0.534);
    }
    .text-color:hover{
      color:white;
      background-color:#38A3A5;
      -webkit-transform: scale(1.03);
      transform: scale(1.2);
    }
    .infos, .galerie, .reservation{
      color:white;
      background-color:#38A3A5;;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
}