.AllCards {
  padding-top: 10%;

  .text {
    font-size: 200%;
    text-align: center;
    border: 2px solid rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    color: white;
    padding: 10px 20px;
    background: rgba(0, 0, 0, 0.3);
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease-in-out;
  }

  .card-margin {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }

  .card-hover {
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: scale(1.08);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);

      .text {
        color: rgb(34, 6, 87);
        background: white;
        text-shadow: none;
        border-color: rgb(34, 6, 87);
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    padding-top: 7%;
    .text {
      font-size: 180%;
    }
    .card-margin {
      gap: 15px;
    }
  }

  @media only screen and (max-width: 767px) {
    padding-top: 5%;
    .card-margin {
      flex-direction: column;
      align-items: center;
      padding: 5%;
    }

    .text {
      font-size: 150%;
      padding: 8px 15px;
    }
  }
}
